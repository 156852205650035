import React from 'react';

const APIDocumentGetHelp = () => {
    return (
      <div style={{ padding: '20px' }}>
        <h2 style={{ fontSize: '30px', color: 'Black', textAlign: 'left' }}>Get Help</h2>
        <p style={{ fontSize: '18px', color: 'grey',  textAlign: 'left' }}>
          Questions? Please email:&nbsp;
          <a href="mailto:michael.b@thelegalleads.com" style={{ color: 'rgb(131, 206, 255)' }}>
            michael.b@thelegalleads.com
          </a>
        </p>
      </div>
    );
  };

export default APIDocumentGetHelp;
