import React from 'react';

const ResponseMessages = () => {
  const jsonResponseExamples = [
    {
      title: "Accepted response",
      json: `{
"unixtime": 1721775411,
"error": false,
"message": "Success - Lead acquired.",
"result": "248685",
"status": "ACCEPTED"
}`
    },
    {
      title: "Duplicated response",
      json: `{
"unixtime": 1721775337,
"error": true,
"message": "DUPLICATE - Preping Rejected",
"status": "DUPLICATE"
}`
    },
    {
      title: "Error response",
      json: `{
"unixtime": 1721775420,
"error": true,
"message": "Error - Missing required data field - lastName",
"status": "ERROR"
}`
    }
  ];

  const errorCodeTable = [
    { code: "1018", message: "Error: account inactive" },
    
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ fontSize: '30px', color: 'Black', textAlign: 'left' }}>Responses</h2>
      <p style={{ fontSize: '18px', color: 'grey', textAlign: 'left' }}>
        All responses are sent back in JSON format. There are 3 possible responses: ACCEPTED, DUPLICATED, ERROR.
      </p>
      {jsonResponseExamples.map((example, index) => (
        <React.Fragment key={index}>
            <h3>{example.title}</h3>
            <pre style={{ 
            backgroundColor: "#f2f2f2", 
            padding: '10px', 
            border: '1px solid #ddd', 
            textAlign: 'left'  // Ensure text is left-aligned
            }}>
            {example.json}
            </pre>
        </React.Fragment>
        ))}
      {/* <h2 style={{ fontSize: '30px', color: 'Black', textAlign: 'left' }}>Error codes</h2>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Code</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Message</th>
          </tr>
        </thead>
        <tbody>
          {errorCodeTable.map((error, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{error.code}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{error.message}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default ResponseMessages;
