// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-container {
    background: rgb(241, 246, 250);
    padding: 10px 0; /* Vertical padding only */
  }
  
  .banner-content {
    padding-left: 26px; /* Adjust this to match the padding of the sidebar */
    max-width: 1200px; /* Adjust the max-width as necessary */
    margin: 0 auto; /* This centers the banner-content within the banner-container */
  }
  
  .banner-title {
    margin: 0 0 5px 0; /* Added bottom margin for spacing */
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .banner-subtitle {
    margin: 0 0 5px 0; /* Added bottom margin for spacing */
    font-size: 16px;
    font-weight: normal;
    color: #333;
  }
  
  .banner-description {
    margin: 0;
    font-size: 14px;
    font-style: italic;
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/APIDocument.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,eAAe,EAAE,0BAA0B;EAC7C;;EAEA;IACE,kBAAkB,EAAE,oDAAoD;IACxE,iBAAiB,EAAE,sCAAsC;IACzD,cAAc,EAAE,gEAAgE;EAClF;;EAEA;IACE,iBAAiB,EAAE,oCAAoC;IACvD,eAAe;IACf,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,iBAAiB,EAAE,oCAAoC;IACvD,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,SAAS;IACT,eAAe;IACf,kBAAkB;IAClB,WAAW;EACb","sourcesContent":[".banner-container {\n    background: rgb(241, 246, 250);\n    padding: 10px 0; /* Vertical padding only */\n  }\n  \n  .banner-content {\n    padding-left: 26px; /* Adjust this to match the padding of the sidebar */\n    max-width: 1200px; /* Adjust the max-width as necessary */\n    margin: 0 auto; /* This centers the banner-content within the banner-container */\n  }\n  \n  .banner-title {\n    margin: 0 0 5px 0; /* Added bottom margin for spacing */\n    font-size: 20px;\n    font-weight: bold;\n    color: #333;\n  }\n  \n  .banner-subtitle {\n    margin: 0 0 5px 0; /* Added bottom margin for spacing */\n    font-size: 16px;\n    font-weight: normal;\n    color: #333;\n  }\n  \n  .banner-description {\n    margin: 0;\n    font-size: 14px;\n    font-style: italic;\n    color: #333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
