import React from 'react';

const APIDocumentTesting = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ fontSize: '30px', color: 'Black', textAlign: 'left' }}>Testing</h2>
      <p style={{ fontSize: '18px', color: 'grey', textAlign: 'left' }}>
        If you wish to FORCE a test on your setup to ensure that all the fields are correctly sent, you can add the following parameters to the API URL. Please note that testing will skip any configured filters or any cap limits.
      </p>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Parameter</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Value</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>lp_action</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>test</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>Required for testing only</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default APIDocumentTesting;
