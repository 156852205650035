// APIDocumentlinstingLeads.js

import React, { useState, useEffect } from "react";

const IngestingLeadsRef = ({ data }) => {
    // Assuming data is an array of objects and we are interested in the 'Category Mapping' array
    const categoryMapping = data && data[0] ? data[0]['Category Mapping'] : [];
  
    // Generate an example payload with fields and example values
    const examplePayload = categoryMapping.reduce((payload, field) => {
      payload[field['API Name']] = field['Format Example'] || 'Example Value';
      return payload;
    }, {});
  
    return (
        <div style={{ padding: '20px' }}>
          <h2 style={{ fontSize: '30px', color: 'Black', textAlign: 'left' }}>Ingesting Leads</h2>
          <p style={{ fontSize: '18px', color: 'grey', textAlign: 'left' }}>
            We only accept POST HTTP requests. Requests can be sent either as FORM or JSON.
          </p>
          <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
            <tbody>
              <tr>
              <td style={{ width: '20%', border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Method:</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>POST</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>URL:</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>https://intake.expressmarketinginc.com/lead/</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>FORM</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  <strong>Headers:</strong><br />
                  Content-Type: application/x-www-form-urlencoded<br />
                  Authorization: YOURAPIKEY <br /><br /><br />
                  <strong>Payload:</strong><br />
                  <span style={{ color: 'green' }}>
                    {Object.entries(examplePayload).map(([key, value], index) => (
                      <React.Fragment key={key}>
                        {key}=<span>{value}&</span>
                        {index < Object.entries(examplePayload).length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>JSON</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  <strong>Headers:</strong><br />
                  Content-Type: application/json<br />
                  Authorization: YOURAPIKEY <br /><br /><br />
                  <strong>Payload:</strong><br />
                  <pre style={{ color: 'green' }}>{JSON.stringify(examplePayload, null, 2)}</pre>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    };
    
    export default IngestingLeadsRef;