import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import APIDocument from './components/APIDocument'; // 导入你的 APIDocument 组件

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/apidocument/:listID" element={<APIDocument />} /> {/* 确保使用 element 属性 */}
                {/* 其他路由 */}
            </Routes>
        </Router>
    );
}

export default App;
