import React from 'react';

const FieldsTable = ({ data }) => {
    
  // Assuming data is an array of objects like the one you provided and we are interested in the 'Category Mapping' array
  const fieldsData = data[0]['Category Mapping'];
  console.log('FieldsTable fieldsData:', fieldsData);

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ fontSize: '30px', color: 'Black', textAlign: 'left' }}>Fields</h2>
      <p style={{ fontSize: '18px', color: 'grey', textAlign: 'left' }}>
    The following fields will be captured by this campaign. For examples of how to submit these fields, please see the examples below.
    </p>
     <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Field Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)'  }}>Type</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)'  }}>Format</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)'  }}>Required</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)'  }}>Description</th>
          </tr>
        </thead>
        <tbody>
        {fieldsData.map((field) => (
            <tr 
              key={field['Category ID']} 
              style={field['Required'] !== "Yes" ? { color: 'grey' } : {fontWeight: '500'}}
            >
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{field['API Name']}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{field['Data Type']}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{field['Format Example']}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{field['Required']}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{field['Description']}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FieldsTable;
