import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'; // 使用 useParams 和 useNavigate
import { message } from "antd";
import axios from "axios";
import NavigationSide from "./APIDocumentNaviGation";
import ContentSection from "./APIDocumentContent";
import '../styles/APIDocument.css';

function APIDocument({ userEmail }) {
    const { listID } = useParams(); // 获取 URL 中的 listID
    const parsedListID = parseInt(atob(listID)) / 18093; // 解码并计算 listID
    const navigate = useNavigate(); // 使用 useNavigate 进行页面导航
    const [data, setData] = useState(null);
    const [activeSection, setActiveSection] = useState('fieldsTable');
    const [listName, setListName] = useState('');
    const [vendorName, setVendorName] = useState('');

    useEffect(() => {
        fetchApiDocument(parsedListID)
            .then(responseData => {
                if (responseData === null) {
                    navigate('/404'); // 如果没有数据，导航到404页面
                } else {
                    setData(responseData);
                    console.log('responseData', responseData[0]);
                    setListName(responseData[0]['List Name']);
                    setVendorName(responseData[0]['Vendor Name']);
                }
            });
    }, [parsedListID, navigate]);

    function fetchApiDocument(listID) {
        const url = '/api/apidocument'; // 指向后端 API
        return axios.get(url, {
            params: {
                listID: listID
            }
        })
        .then(response => {
            if (response.status === 200) {
                try {
                    const detailPayload = JSON.parse(response.data.listingDetailPayload);
                    console.log("API document parsed successfully:", detailPayload);
                    return detailPayload;
                } catch (error) {
                    console.error("Parsing error:", error);
                    message.error("Failed to parse API document.");
                }
            }
        })
        .catch(error => {
            console.error("Error fetching API document:", error);
            message.error("Failed to fetch API document.");
        });
    }

    return (
        <div>
            {/* Banner */}
            <div className="banner-container">
                <div className="banner-content">
                    <h1 className="banner-title">{listName}</h1>
                    <p className="banner-subtitle">{vendorName} - API Tech Specs Documentation</p>
                    <p className="banner-description">
                        This document provides all the information needed to successfully process leads for this campaign.
                    </p>
                </div>
            </div>

            <div style={{ display: 'flex', height: 'calc(100vh - 60px)' }}>
                <div style={{
                    position: 'sticky',
                    top: 0,
                    alignSelf: 'flex-start',
                    height: 'calc(100vh - 60px)',
                    overflowY: 'auto',
                    width: '256px',
                }}>
                    <NavigationSide setActiveSection={setActiveSection} />
                </div>
                <div style={{
                    marginLeft: '26px',
                    width: 'calc(100% - 256px)',
                    overflowY: 'auto'
                }}>
                    <ContentSection activeSection={activeSection} data={data} />
                </div>
            </div>
        </div>
    );
}

export default APIDocument;
